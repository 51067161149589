export default [
  {
    id: Math.random(),
    text: "home",
    url: "/",
  },
  {
    id: Math.random(),
    text: "projects",
    url: "/courses",
  },
  {
    id: Math.random(),
    text: "about",
    url: "/about",
  },
  {
    id: Math.random(),
    text: "contact",
    url: "/contact",
  },
]
